.lines-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  pointer-events: none;
}

.line {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: 0% 0%;
  border-radius: 5px; /* Make the edges rounded */
  animation: move-line  linear infinite;
}


@keyframes move-line {
  0% {
    transform: rotate(calc(var(--angle))) translate(40vh, 0);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: rotate(calc(var(--angle))) translate(200vh, 0); /* Move line out of view */
    opacity: 1;
  }
}

/* AnimatedLines.css */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  opacity: 0; /* Ensure initial opacity is 0 */
  animation: fadeIn 1s ease-in forwards;
}


.topic-input {
  border: white;
  outline: none;
  border-radius: 5px;
  box-shadow: none;
  padding: 1em;
  margin: 0.5em;
  flex-grow: 1; /* Makes input take up the remaining space */
}
.topic-input:focus {
  outline: none;
  border-bottom: 2px solid #0056b3;
  
}
.point-input {
  box-shadow: none;
  border: none;
  outline: none;
  padding: 1em;
  background-color: transparent;
  width: 4em;
  margin: 0.1em;
  /* Add a small padding-bottom to reduce the gap if needed */
}

.point-input:focus {
  border: none;
  border-bottom: 2px solid #0056b3;
}
.rubric-input {
  box-shadow: none;
  border: none;
  outline: none;
  padding: 5px 10px;
  background-color: transparent;
  width: 4em;
  margin: 0.1em;
  /* Add a small padding-bottom to reduce the gap if needed */
  padding-bottom: 2px;
}

.rubric-input:focus {
  border: none;
  border-bottom: 2px solid #0056b3;
}
.input-wrapper {
  border: none;
  outline: none;
  display: inline-block;
  margin: 0.5em;
  position: relative;
}

.input-wrapper::after {
  border: none;
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0; /* Initially set to 0 */
  height: 2px;
  transition: width 0.3s;
}

.input-wrapper:focus-within::after {
  border: none;
  width: 100%; /* Expand underline to full width on focus */
}
.feedback-container {
  margin-top: 20px;
}

.feedback-box {
  border: 1px solid #ddd;
  padding: 10px;
  width: 100%;
  overflow-y: auto; /* Makes the box scrollable */
  background-color: #f9f9f9; /* Light background for the feedback */
  border-radius: 5px;
}

.dropzone {
  border: 2px dashed #ccc;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
  transition: border 0.3s ease-in-out;
}

.dropzone:hover {
  border-color: #888;
}

.file-list {
  list-style: none;
  padding: 0;
}

.file-list li {
  margin-bottom: 5px;
}


.delete-btn {
  margin-left: 1em;
  background-color: white;
  color: grey;
  border: none;
}

.delete-btn:hover {
  color: red;
  background-color: white;

}

.command-item-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

.markdownstyling p {
  margin-bottom: 15px;
}

